
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';

import { User } from '../models/user';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthService {
  private BASE_URL = '/api/';
  private headers: HttpHeaders = new HttpHeaders({'Content-Type': 'application/json'});
  constructor(private http: HttpClient) {}
  login(user: User): Promise<any> {
    return this.http.post(this.BASE_URL + 'user/signin', user, {headers: this.headers})
                    // .pipe(map((res: Response) => res.json()))
                    .toPromise()
                    .catch(this.handleError);
  }
  serviceLogin(user: User): Promise<any> {
    return this.http.post(this.BASE_URL + 'user/serviceSignin', user, {headers: this.headers})
                    // .pipe(map((res: Response) => res.json()))
                    .toPromise()
                    .catch(this.handleError);
  }
  logout(token: string): Promise<any> {
    let headers = this.headers.set('Authorization', '');
    if (!(localStorage.getItem('token') === null)) {
       headers = this.headers.set('Authorization', localStorage.getItem('token'));
    }
    return this.http.post(this.BASE_URL + 'user/signout', '{}' , {headers: headers})
                    // .pipe(map((res: Response) => res.json()))
                    .toPromise()
                    .catch(this.handleError);
  }
  register(user: User): Promise<any> {
    return this.http.post(this.BASE_URL + 'user/signup', user, {headers: this.headers})
                    // .pipe(map((res: Response) => res.json()))
                    .toPromise()
                    .catch(this.handleError);
  }
  global(): Promise<any> {
    return this.http.post(this.BASE_URL + 'global/data', {}, {headers: this.headers})
                    // .pipe(map((res: Response) => res.json()))
                    .toPromise()
                    .catch(this.handleError);
  }
  ensureAuthenticated(path: string): Promise<any> {
    // var headers = this.headers.set('Authorization', token);
    let headers = this.headers.set('Authorization', '');
    if (!(localStorage.getItem('token') === null)) {
       headers = this.headers.set('Authorization', localStorage.getItem('token'));
    }
    return this.http.post(this.BASE_URL + 'user/status', { path: path } , {headers: headers})
                    // .pipe(map((res: Response) => res.json()))
                    .toPromise()
                    .catch(this.handleError);
  }
  genCode(profile: any): Promise<any> {
    // var headers = this.headers.set('Authorization', localStorage.getItem('token'));
    return this.http.post(this.BASE_URL + 'user/genCode', profile, {headers: this.headers})
                    // .pipe(map((res: Response) => res.json()))
                    .toPromise()
                    .catch(this.handleError);
  }
  updPassword(profile: any): Promise<any> {
    // var headers = this.headers.set('Authorization', localStorage.getItem('token'));
    let headers = this.headers.set('Authorization', '');
    if (!(localStorage.getItem('token') === null)) {
       headers = this.headers.set('Authorization', localStorage.getItem('token'));
    }
    return this.http.post(this.BASE_URL + 'user/updPassword', profile, {headers: headers})
                    // .pipe(map((res: Response) => res.json()))
                    .toPromise()
                    .catch(this.handleError);
  }

  private handleError (error) {
    console.log(error);
    // window.location.reload();
    return observableThrowError(error);
  }
}
