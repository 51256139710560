import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService, UserService, DeviceService, TicketService, DBGlobal, MapQuestService,
         ArchiveService, AlertService, MsgService } from './shared/services';
import {  AuthGuard, SignGuard } from './shared/guard';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import {IMqttMessage, MqttModule, IMqttServiceOptions} from 'ngx-mqtt';

export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
    hostname: 'czbbabfy:czbbabfy:RvKTuMBqWnmM5ReG0xl6pLoNiRqT0pI8@tiny-silver-baboon.rmq.cloudamqp.com',
    port: 443,
    protocol: 'wss',
    path: '/ws/mqtt'
  };

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        FormsModule, ReactiveFormsModule,
        AppRoutingModule,
        NgbModule,
        HttpClientModule,
        MqttModule.forRoot(MQTT_SERVICE_OPTIONS)
    ],
    providers: [AuthGuard, AuthService, SignGuard, UserService, DeviceService, TicketService,
                DBGlobal, ArchiveService, AlertService, MsgService, MapQuestService],
    bootstrap: [AppComponent]
})
export class AppModule {
}
