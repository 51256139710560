import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private auth: AuthService) {}

    canActivate() {
        if (localStorage.getItem('isLoggedin')) {
            this.auth.ensureAuthenticated('')
            .then((res) => {
                // console.log(res.success);
                if (res.success) {
                    // console.log('logged in');
                    // this.router.navigateByUrl(this.router.url);
                    // return true;
                    const isOperator = localStorage.getItem('isOperator');
                    console.log('isOperator(2) ', isOperator);
                    if (isOperator && isOperator === 'true') {
                        this.router.navigate(['/operations/devices']);
                    } else {
                        this.router.navigate(['/device']);
                    }
                } else {
                    localStorage.removeItem('token');
                    localStorage.removeItem('isLoggedin');
                    localStorage.removeItem('isOperator');
                    localStorage.removeItem('isService');
                    this.router.navigate(['/login']);
                    // return false;
                }
            })
            .catch((err) => {
                console.log(err);
                localStorage.removeItem('token');
                localStorage.removeItem('isLoggedin');
                localStorage.removeItem('isOperator');
                localStorage.removeItem('isService');
                this.router.navigate(['/login']);
                // return false;
            });

            // console.log('logged in');
            return true;
        } else if (localStorage.getItem('isService')) {
            this.auth.ensureAuthenticated(localStorage.getItem('isService'))
            .then((res) => {
                // console.log(res.success);
                if (res.success) {
                    // console.log('logged in');
                } else {
                    localStorage.removeItem('token');
                    localStorage.removeItem('isService');
                    localStorage.removeItem('isOperator');
                    localStorage.removeItem('isLoggedin');
                    this.router.navigate(['/service/login']);
                }
            })
            .catch((err) => {
                console.log(err);
                localStorage.removeItem('token');
                localStorage.removeItem('isService');
                localStorage.removeItem('isOperator');
                localStorage.removeItem('isLoggedin');
                this.router.navigate(['/service/login']);
            });
            return true;
        
        } else {
            
            localStorage.removeItem('token');
            localStorage.removeItem('isLoggedin');
            localStorage.removeItem('isOperator');
            localStorage.removeItem('isService');
            this.router.navigate(['/login']);
            return false;
        }
    }
}
